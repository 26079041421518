import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistStore, persistReducer } from 'redux-persist'
import MotoformDataReducer from "./reducers/MotoformDataReducerSlice";
import SocketReducer from "./reducers/socketReducer";
import GroupMedicalReducer from "./reducers/GroupMedicalDataReducerSlice";
import YachtReducer from "./reducers/YachtDataReducerSlice";
import lobReducer from './reducers/lobSlice'; // Import your slice reducer

const lobPersistConfig = {
  key: "lob",
  storage,
};

const store = configureStore({
  reducer: {
    lob: persistReducer(lobPersistConfig, lobReducer),  // Add the LOB reducer here
    MotoformDataReducer,
    SocketReducer,
    GroupMedicalReducer,
    YachtReducer
  },
});
const persistor = persistStore(store);
export { store, persistor }
