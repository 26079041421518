// slices/lobSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { identifyLOB } from '../../utils';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
    lob: '',
    id: ""
};

const lobMapping = {
    Motor: [
        "Chasisno", "Carbasicinfo", "Carpolicyinfo", "Carmodelyear", "Carmaker", "Carmodel", "Carvariant",
        "SelectCarvalue", "Carregisterlocation", "Carspecification", "Personaldetails", "Nationality",
        "Uaedrivingexp", "Lastclaim", "Getquote", "Quotes", "thankyou"
    ],
    Medical: [
        "Individualpolicy", "Individualinsurancepersonaldetails", "Individualcountry", "Individualinsuranceids",
        "Individualmetrics", "Individualinsurancequotes", "Individualinsurancepersonaldetails3",
        "Individualinsurancepersonaldetails2", "Individualinsurancematernity", "Individualinsuranceunderwriting",
        "Individualinsurancequote"
    ],
    Travel: [
        "Traveldetails", "Traveldetailsform", "Travelplantype", "Travelpersonalform", "Familydetails",
        "Beneficarydetails", "Travelquotes", "TravelSelectedquotes", "TravelPayments"
    ],
    Home: [
        "Homeinsurance", "Homeplan", "Homevalue", "Homehelper", "Individualmetrics", "Homepersonaldetails",
        "Homecondition", "Homecondition2", "Homequotes"
    ],
    Yatch: [
        "Yachtdetails", "YachtlYear", "YachtMaker", "YachtVarient", "Enginedetails", "Suminsured",
        "yachtpersonaldetails", "Territorycoverage", "Claimsexperience", "OpearatorExperience", "Yachtquotes"
    ],
};

const lobSlice = createSlice({
    name: 'lob',
    initialState,
    reducers: {
        setLob: (state, action) => {
            state.lob = action.payload
            if (action.payload) {

                console.log("payload of lob", action.payload)
                // Call the identifyLOB function based on the LOB
                if (!state.id) {
                    let id = uuidv4()
                    state.id = id
                    identifyLOB({ path: action.payload, id });
                } else {
                    identifyLOB({ path: action.payload, id: state.id });
                }
            }

        },
    },
});

// Export the action
export const { setLob } = lobSlice.actions;

// Export the reducer to use in the store
export default lobSlice.reducer;
