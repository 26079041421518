import { API_URL } from ".."
const locationData = {
    "Motor": ["Chasisno"],
    "Medical": ["Individualpolicy"],
    "Travel": ["Traveldetails"],
    "Home": ["Homeinsurance"],
    "Yatch": ["Yachtdetails"],
    "OtherInsurance": ["Otherinsurance"],
    'Homepage': ['']
};

export const identifyLOB = (data) => {
    try {
        let currentPath = data?.path
        console.log({currentPath})
        if (currentPath === "left") {
            handleViewingLob({ id: data?.id, matchedLob: "left" });
        } if (currentPath === "Homepage") {
            handleViewingLob({ id: data?.id, matchedLob: "Homepage" });
        }
        // Check which LOB the current page belongs to
        let matchedLob = null;
        for (const [lob, locations] of Object.entries(locationData)) {
            if (currentPath && locations.includes(currentPath)) {
                matchedLob = lob;
                break;
            }
        }
        if (matchedLob) {
            console.log("Matched LOB:", matchedLob);
            handleViewingLob({ id: data?.id, matchedLob });
        } else {
            console.log("No matching LOB found.");
        }
    } catch (error) {
        console.log("Error in identifyLOB:", error);
    }
};

const handleViewingLob = (data) => {
    try {
        const { matchedLob, id } = data
        let userType = 'unregistered'
        if (matchedLob && id) {
            const token = localStorage.getItem("usertoken")
            if (token) {
                userType = 'registered'
            }
            const requestOptions = {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    lob: matchedLob,
                    id: id,
                    reg_type: userType
                })
            };
            fetch(`${API_URL}/api/updateViewingLob`, requestOptions)
                .then(response => response.json())
                .then(data => console.log("API Response:", data))
                .catch(error => console.log("API Error:", error));
        }
    } catch (error) {
        console.log("Error in handleViewingLob:", error);
    }
};

