import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MotorContextAppProvider } from './MultiStepContextApi';
import { UserContextAppProvider } from './UserContextAppProvider';
import { store, persistor } from './redux/store';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <UserContextAppProvider>
        <MotorContextAppProvider>
          <App />
        </MotorContextAppProvider>
      </UserContextAppProvider>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

reportWebVitals();

export const API_URL = "https://lmp.handsintechnology.in/"
// export const API_URL = "http://localhost:8007"
const hostname = window.location.host
export const forntendurl = window.location.protocol + "//" + hostname;
